import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Button, Text } from "grommet"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import ServiceBenefits from "../../components/servicebenefits"

export default function DesignServices() {
  return (
    <Layout>
      <Seo title="Design Services" />

      <Box>
        <Box
          width="xlarge"
          alignSelf="center"
          margin={{ bottom: "large" }}
          gap="large"
          pad={{ horizontal: "medium" }}
          direction="row-responsive"
        >
          <Box
            basis="1/3"
            border={{ side: "top", color: "light-1", size: "medium" }}
            pad={{ top: "medium" }}
          >
            <Heading
              level={2}
              margin={{ top: "small", bottom: "small" }}
              size="small"
            >
              De-risk ideas and design cutting edge services in less than 60
              days
            </Heading>
            <Box as="ul">
              <Text as="li">Design disruptive ventures</Text>
              <Text as="li">Re-define your market</Text>
              <Text as="li">Validated propositions that work</Text>
              <Text as="li" margin={{ bottom: "medium" }}>
                Dedicated multidisciplinary team
              </Text>
            </Box>
            <Link to="/contact" >
              <Button
                as="span"
                primary
                color="brand"
                size="medium"
                label="Speak to the team"
              />
            </Link>
          </Box>
          <Box
            basis="1/3"
            border={{ side: "top", color: "light-1", size: "medium" }}
            pad={{ top: "medium" }}
          >
            <Heading
              level={2}
              margin={{ top: "small", bottom: "small" }}
              size="small"
            >
              Uncover untapped potential and find your competitive advantage
            </Heading>
            <Box as="ul">
              <Text as="li">Find new niche markets</Text>
              <Text as="li">Identify competitive advantages</Text>
              <Text as="li">Create the business case</Text>
              <Text as="li" margin={{ bottom: "medium" }}>
                Define product roadmaps
              </Text>
            </Box>

            <Link to="/contact" >
              <Button
                as="span"
                primary
                color="brand"
                size="medium"
                label="Speak to the team"
              />
            </Link>
          </Box>
          <Box
            basis="1/3"
            border={{ side: "top", color: "light-1", size: "medium" }}
            pad={{ top: "medium" }}
          >
            <Heading
              level={2}
              margin={{ top: "small", bottom: "small" }}
              size="small"
            >
              Accelerate user centred delivery across your teams
            </Heading>
            <Box as="ul">
              <Text as="li">
                Empower your team deliver cutting edge services
              </Text>
              <Text as="li">Mentorship & coaching from our experts</Text>
              <Text as="li">Bootcamps and toolkits to rapidly upskill</Text>
              <Text as="li" margin={{ bottom: "medium" }}>
                Validate a new way of working
              </Text>
            </Box>
            <Link to="/contact" >
              <Button
                as="span"
                primary
                color="brand"
                size="medium"
                label="Speak to the team"
              />
            </Link>
          </Box>
        </Box>
        <Box alignSelf="center" width="xlarge">
          <ServiceBenefits />
        </Box>
      </Box>
    </Layout>
  )
}
